var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "mail-box"
  }, [_c('div', {
    staticClass: "mail-box-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("致客户的一封信")]), _c('img', {
    staticClass: "closeIcon",
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/other/closed.png"
    },
    on: {
      "click": _vm.closeMail
    }
  }), _c('div', {
    staticClass: "mail-scroll"
  }, [_c('div', {
    staticClass: "lines bt"
  }, [_vm._v("抱歉、让您久等了！")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("12.20日零点电商平台正式开启下单，9:00人工开票")]), _c('div', {
    staticClass: "lines bt"
  }, [_vm._v("服务。")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("由于货量无法估计，送货周期可能约7天。急需者请")]), _c('div', {
    staticClass: "lines bt"
  }, [_vm._v("谨慎下单。")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("1:我们依然承诺1盒起送")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("2:我们保证药品平价供应")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("3:个别商品单天限量供应、尽最大努力合理分配。")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("4:恳请各位理性购买、不囤货、不积压、不恶意拖款")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("（公司进货都需预付款）")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("5:每天10:00—20:00都有货入库，大家可随时关注")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("电商。")]), _c('div', {
    staticClass: "lines bt"
  }, [_vm._v("6:如需退货，请暂缓（我们依然履行最后退货时间）")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("当前物资紧张短缺，仓库员工带病坚持，我们尽最大")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("努力全力以赴。")]), _c('div', {
    staticClass: "lines bt"
  }, [_vm._v("事未尽圆、不到之处、给份理解")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("保物资、保平价、保配送。")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("克万难、有责任、敢担当。")]), _c('div', {
    staticClass: "lines bt"
  }, [_vm._v("努力成为被认可、有影响、有温度并且受尊重的企业。")]), _c('div', {
    staticClass: "lines kk"
  }, [_vm._v("空")]), _c('div', {
    staticClass: "lines"
  }, [_vm._v("祝：身体健康、迎接阳光，一路走来、感谢有您。")]), _c('div', {
    staticClass: "lines",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v("普健医药")]), _c('div', {
    staticClass: "lines la_pa"
  }), _c('div', {
    class: ['scrImg', 'animated', _vm.isShow ? 'fadeOutUp' : '']
  }, [_c('img', {
    attrs: {
      "src": "https://obs.pujian.com/frontend/app/other/mail4.png"
    }
  })])])]), _c('div', {
    staticClass: "mail-box-bg"
  }, [_c('div', {
    staticClass: "mail-box-bg-btn",
    on: {
      "click": _vm.closeMail
    }
  }, [_vm._v(" 收下了 ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };