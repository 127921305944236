<template>
  <div class="notice-wrap">
    <b-message
      v-model="isActive"
      title="红包已到账通知"
      aria-close-label="Close message"
      :duration="5000"
      auto-close
      @close="isActive = false">
      <div class="message-content">
        <span>{{ activityMessage.content }}</span>
        <!--<span class="look-over" @click="toOrderDetail">-->
        <!--  去看看<i class="iconfont icon-arrow-right"></i>-->
        <!--</span>-->
      </div>
    </b-message>
  </div>
</template>

<script>
import { Account, AccountStorage } from "@/services/account";
const account = new Account();
const accountStorage = new AccountStorage();
let timer;

export default {
  name: "index",
  data() {
    return {
      isActive: false,
      activityMessage: {}
    }
  },
  created() {
    this.clearTimer()
    this.isLogin = account.isLogined();
    if (this.isLogin) this.promotionActivity()
  },
  methods: {
    async promotionActivity() {
      let params = {
        appId: 1,
        page: 1,
        size: 1,
        loginCompanyAccount: accountStorage.getCacheUserInfo().account,
        type: 3, //消息分类
        readStatus: 0,  // 0.未读  1.已读
      }
      const url = "/xpaas-message-center/api/v1/message/pm/pool/promotionActivity";
      let result = await this.$getHttpClient({useSpanner:false}).post(url, params);
      let data = result.data.data || {};
      if (data.content && data.content.length) {
        this.activityMessage = data.content[0];
        this.isActive = true;
        timer = setTimeout(() => {
          this.setAllRead()
          this.clearTimer()
        }, 5000)
      }
    },
    // 设置消息未读
    async setAllRead() {
      const URL = "/xpaas-message-center/api/v1/message/pm/pool/updateStatusAll";
      const parms = {
        appId: 1,
        type: 3, //消息分类
        companyAccount: accountStorage.getCacheUserInfo().account
      };
      await this.$getHttpClient().post(URL, parms);
    },
    clearTimer() {
      this.isActive = false;
      // 清除定时器
      clearTimeout(timer)
      timer = null
    },
    toOrderDetail() {
      this.$router.push({
        path: "/user-center/my-account"
      });
    }
  }
}
</script>

<style scoped lang="scss">
.notice-wrap{
  position: fixed;
  right: 10px;
  top: 60px;
  z-index: 1000;
  width: 300px;
  box-shadow: 0 5px 22px rgba(0, 0, 0, 0.2);

  .message-content{
    text-align: left;

    .look-over{
      color: #3875F6;
      margin-left: 5px;
      cursor: pointer;

      .iconfont{
        font-size: 14px;
      }
    }
  }
}
</style>
