var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home-page"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    },
    on: {
      "update": _vm.update
    }
  }), _c('HeadeSearch', {
    ref: "mychild"
  }), _c('MainTabs', {
    attrs: {
      "activeIndex": _vm.tabActive
    },
    on: {
      "getCurrentData": _vm.onClickTab
    }
  }), _c('div', {
    staticClass: "main-banner-wrap"
  }, [_c('div', {
    staticClass: "main-banner-right"
  }, [_c('div', {
    staticClass: "banner-left-wrap"
  }, [_vm.carousels.length > 0 ? _c('div', {
    staticClass: "banner-wrap"
  }, [_c('b-carousel', {
    attrs: {
      "autoplay": true,
      "pause-info": false
    }
  }, _vm._l(_vm.carousels, function (item, i) {
    return _c('b-carousel-item', {
      key: i
    }, [_c('section', {
      staticStyle: {
        "height": "400px"
      }
    }, [_c('img', {
      staticClass: "banner-img",
      attrs: {
        "src": item.imageLinksPc,
        "alt": ""
      },
      on: {
        "mousedown": function mousedown($event) {
          return _vm.toProductDetail(item.productTypePc, item.pcUrl, item.productPcId, item.id);
        }
      }
    })])]);
  }), 1)], 1) : _vm._e(), _c('div', {
    staticClass: "banner-img-wrap"
  }, [_vm.subAdvertising[1] ? _c('div', {
    staticClass: "banner-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.subAdvertising[1].imageLinks
    },
    on: {
      "click": function click($event) {
        return _vm.toProductDetail(_vm.subAdvertising[1].productTypePc, _vm.subAdvertising[1].pcUrl, _vm.subAdvertising[1].productPcId);
      }
    }
  })]) : _vm._e(), _vm.subAdvertising[2] ? _c('div', {
    staticClass: "banner-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.subAdvertising[2].imageLinks
    },
    on: {
      "click": function click($event) {
        return _vm.toProductDetail(_vm.subAdvertising[2].productTypePc, _vm.subAdvertising[2].pcUrl, _vm.subAdvertising[2].productPcId);
      }
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "banner-right-wrap"
  }, [_vm.subAdvertising[0] ? _c('div', {
    staticClass: "banner-right-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.subAdvertising[0].imageLinks,
      "alt": ""
    },
    on: {
      "click": function click($event) {
        return _vm.toProductDetail(_vm.subAdvertising[0].productTypePc, _vm.subAdvertising[0].pcUrl, _vm.subAdvertising[0].productPcId);
      }
    }
  })]) : _vm._e(), _vm.subAdvertising[3] ? _c('div', {
    staticClass: "banner-right-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.subAdvertising[3].imageLinks
    },
    on: {
      "click": function click($event) {
        return _vm.toProductDetail(_vm.subAdvertising[3].productTypePc, _vm.subAdvertising[3].pcUrl, _vm.subAdvertising[3].productPcId);
      }
    }
  })]) : _vm._e()])])]), _c('PageFooter'), _c('b-modal', {
    attrs: {
      "trap-focus": "",
      "aria-role": "dialog",
      "aria-label": "Example Modal",
      "close-button-aria-label": "Close",
      "aria-modal": "",
      "can-cancel": false,
      "has-modal-card": true,
      "destroy-on-hide": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('Mail', {
          on: {
            "closeMail": _vm.closeMail
          }
        })];
      }
    }]),
    model: {
      value: _vm.mailDialog,
      callback: function callback($$v) {
        _vm.mailDialog = $$v;
      },
      expression: "mailDialog"
    }
  }), _c('b-modal', {
    attrs: {
      "trap-focus": "",
      "aria-role": "dialog",
      "aria-label": "Example Modal",
      "close-button-aria-label": "Close",
      "aria-modal": "",
      "can-cancel": true,
      "has-modal-card": true,
      "destroy-on-hide": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "coupon-dialog"
        }, [_c('div', {
          staticClass: "popCoupon_box_content"
        }, [_vm.couponList.length ? _c('div', {
          staticClass: "title"
        }, [_vm._v("恭喜！获得" + _vm._s(_vm.couponList[0].couponName == "99-5" ? "5" : "10") + "元优惠券")]) : _vm._e(), _vm._l(_vm.couponList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "coupon-list-box-item"
          }, [_c('div', {
            staticClass: "coupon-list-box-item-le"
          }, [item.couponName == '99-5' ? _c('div', {
            staticClass: "item-le-top"
          }, [_c('span', [_vm._v("￥"), _c('span', {
            class: [item.couponName == '99-5' ? 'tx1' : 'tx']
          }, [_vm._v("5")])])]) : _c('div', {
            staticClass: "item-le-top"
          }, [_c('span', [_vm._v("￥"), _c('span', {
            class: [item.couponName == '99-5' ? 'tx1' : 'tx']
          }, [_vm._v("5")])]), _c('span', [_vm._v("或")]), _c('span', [_vm._v("￥"), _c('span', {
            class: [item.couponName == '99-5' ? 'tx1' : 'tx']
          }, [_vm._v("10")])])]), item.couponName == '99-5' ? _c('p', {
            staticClass: "item-le-bootm"
          }, [_vm._v("满99元可用")]) : _c('div', {
            staticClass: "item-le-bootm-box"
          }, [_c('p', {
            staticClass: "item-le-bootm"
          }, [_vm._v("满99元可用5元")]), _c('p', {
            staticClass: "item-le-bootm"
          }, [_vm._v("满199元可用10元")])]), _c('div', {
            staticClass: "r-box"
          }), _c('div', {
            staticClass: "r-box1"
          })]), _c('div', {
            staticClass: "coupon-list-box-item-ri"
          }, [_c('div', {
            staticClass: "item-ri-con"
          }, [_c('p', [_vm._v(_vm._s(item.couponRange == 0 ? "全场通用券" : ""))]), _c('p', [_vm._v(_vm._s(item.couponEndTime) + "后过期")])]), _c('div')])]);
        })], 2), _c('span', {
          staticClass: "popCoupon_box_btn",
          on: {
            "click": _vm.nowUse
          }
        }, [_vm._v("立即使用")])])];
      }
    }]),
    model: {
      value: _vm.couponDialog,
      callback: function callback($$v) {
        _vm.couponDialog = $$v;
      },
      expression: "couponDialog"
    }
  }), _c('redPacketNotice')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };