export default {
  name: "mail",
  data: function data() {
    return {
      isShow: true
    };
  },
  methods: {
    closeMail: function closeMail() {
      this.$emit("closeMail", true);
    }
  }
};